




















































import {Component, Prop, Provide, Vue} from 'vue-property-decorator'
import {Explorer} from '@/model/resource/Explorer'
import {InputExplorerSchema} from '@/schema/resource/Explorer/InputExplorerSchema'

@Component
export default class PersistExplorerView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputExplorerSchema()
  explorer = new Explorer()

  async created() {
    this.populateResource()
    await this.populate()
  }

  populateResource() {
    this.schema.collectionBlockchain.queryAsPage()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.explorer.getExplorer(id)
    }

    this.$await.done('getExplorer')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.explorer.persistExplorer()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/explorer/list')
  }
}
